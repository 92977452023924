export default {
  init() {
     // JavaScript to be fired on all pages
    const header = document.querySelector('header.banner')
    const bodyWrapper = document.querySelector('header.banner + .wrap')
    let headerHeight = header.offsetHeight
    
    // Set the padding top of the body wrapper to the height of the header
    bodyWrapper.style.paddingTop = headerHeight + 'px'
     
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
